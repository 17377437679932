@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Inter', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
