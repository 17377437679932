.App {
  text-align: center;
}

.site-page .actions-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.btn-delete {
  background-color: red;
  padding: 1rem;
  font-weight: bolder;
  color: white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.App-header h1 {
  background-color: #838e90;
  margin: 0;
  padding: 0.5rem;
  color: white;
}

.App-user-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #5c6465;
  color: white;
  position: sticky;
  top: 0;
}
.App-user-infos button {
  background-color: transparent;
  border: none;
  color: inherit;
}
.App-user-infos button:hover {
  color: red;
  cursor: pointer;
}

.menu-principal .menu-item {
  position: relative;
}
.menu-principal .menu-item label {
  padding: 0.2rem 1rem;
}
.menu-principal .menu-item ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #5c6465;
  padding: 1rem;
  width: max-content;
}
.menu-principal .menu-item ul li {
  padding: 0.5rem;
  margin: 1px;
  font-size: 1rem;
}
.menu-principal .menu-item ul li a {
  color: inherit;
  text-decoration: none;
}
.menu-principal .menu-item ul li:hover {
  background-color: #838e90;
}
.menu-principal .menu-item:hover ul {
  display: block;
}
.menu-principal .menu-item:hover label {
  background-color: #838e90;
}

.home-dev-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.anthology-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

