.form {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: fit-content;
  margin: 1rem auto;
  padding: 1.5rem;
  label {
    font-weight: bolder;
  }
  input {
    width: fit-content;
    margin: 0.5rem auto;
  }
  .api-urls {
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    padding: 1rem;
  }
}
.user-edit-form {
  .roles {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: fit-content;
    margin: auto;
  }
}
.my-account {
  .error {
    background-color: red;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  .container {
    border: 1px solid black;
    min-height: 300px;
    max-width: 340px;
    margin: 40px auto;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: hi 0.5s;
    transform: translateZ(0px);

    * {
      box-sizing: border-box;
    }
    h2 {
      text-align: center;
      padding: 1rem 0 0 0;
    }
  }

  .pages {
    flex: 1;
    white-space: nowrap;
    position: relative;
    transition: all 0.4s;
    display: flex;
    .page {
      min-width: 100%;
      padding: 20px 15px;
      padding-top: 0px;
      &:nth-of-type(1) {
        .input {
          transform: translateX(-100%) scale(0.5);
        }
      }
      &:nth-of-type(2) {
        .input {
          transform: translateX(100%) scale(0.5);
        }
      }
      .input {
        transition: all 1s;
        opacity: 0;
        transition-delay: 0s;
      }

      .title {
        text-align: left;
        margin-bottom: 10px;
        font-size: 14px;
        position: relative;
        line-height: 14px;
        i {
          vertical-align: text-bottom;
          font-size: 19px;
        }
      }
      .input {
        margin-top: 20px;
      }
      input.text {
        background: #f6f7f9;
        border: none;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0px 10px;
        color: rgba(0, 0, 0, 0.5);
        outline: none;
      }

      input[type='submit'] {
        color: #f6f7f9;
        height: 40px;
        line-height: 40px;
        width: 100%;
        border: none;
        border-radius: 4px;
        font-weight: 600;
        background: rgb(0, 0, 0);
        cursor: pointer;
        &[disabled] {
          cursor: no-drop;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .tabs {
    max-height: 50px;
    height: 50px;
    display: flex;
    background: #fff;

    .tab {
      flex: 1;
      color: #5d708a;
      text-align: center;
      line-height: 50px;
      transition: all 0.2s;
      .text {
        font-size: 14px;
        transform: scale(1);
        transition: all 0.2s;
      }
    }
  }

  .tabs .tab.show {
    box-shadow: inset (6 * 0) + -3px 2px 5px rgba(0, 0, 0, 0.25);
    color: #3f4c7f;
    &.signup {
      box-shadow: inset (6 * 1) + -3px 2px 5px rgba(0, 0, 0, 0.25);
    }
    .text {
      transform: scale(0.9);
    }
  }

  .pages {
    &.signup-page {
      transform: translateX(-100% * 1);
    }
    &.login-page {
      transform: translateX(-100% * 0);
    }

    .page.show {
      .input {
        opacity: 1;
        transform: translateX(0%);
        transition: all 0.5s;
        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            transition-delay: 0.2s * $i;
          }
        }
      }
    }
  }

  @keyframes hi {
    from {
      transform: translateY(50%) scale(0, 0);
      opacity: 0;
    }
  }
}
