.form {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: fit-content;
  margin: 1rem auto;
  padding: 1.5rem;
}
.form label {
  font-weight: bolder;
}
.form input {
  width: fit-content;
  margin: 0.5rem auto;
}
.form .api-urls {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  padding: 1rem;
}

.user-edit-form .roles {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: fit-content;
  margin: auto;
}

.my-account .error {
  background-color: red;
  color: white;
  padding: 1rem;
  text-align: center;
}
.my-account .container {
  border: 1px solid black;
  min-height: 300px;
  max-width: 340px;
  margin: 40px auto;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: hi 0.5s;
  transform: translateZ(0px);
}
.my-account .container * {
  box-sizing: border-box;
}
.my-account .container h2 {
  text-align: center;
  padding: 1rem 0 0 0;
}
.my-account .pages {
  flex: 1;
  white-space: nowrap;
  position: relative;
  transition: all 0.4s;
  display: flex;
}
.my-account .pages .page {
  min-width: 100%;
  padding: 20px 15px;
  padding-top: 0px;
}
.my-account .pages .page:nth-of-type(1) .input {
  transform: translateX(-100%) scale(0.5);
}
.my-account .pages .page:nth-of-type(2) .input {
  transform: translateX(100%) scale(0.5);
}
.my-account .pages .page .input {
  transition: all 1s;
  opacity: 0;
  transition-delay: 0s;
}
.my-account .pages .page .title {
  text-align: left;
  margin-bottom: 10px;
  font-size: 14px;
  position: relative;
  line-height: 14px;
}
.my-account .pages .page .title i {
  vertical-align: text-bottom;
  font-size: 19px;
}
.my-account .pages .page .input {
  margin-top: 20px;
}
.my-account .pages .page input.text {
  background: #f6f7f9;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.5);
  outline: none;
}
.my-account .pages .page input[type=submit] {
  color: #f6f7f9;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  background: rgb(0, 0, 0);
  cursor: pointer;
}
.my-account .pages .page input[type=submit][disabled] {
  cursor: no-drop;
  background: rgba(0, 0, 0, 0.5);
}
.my-account .tabs {
  max-height: 50px;
  height: 50px;
  display: flex;
  background: #fff;
}
.my-account .tabs .tab {
  flex: 1;
  color: #5d708a;
  text-align: center;
  line-height: 50px;
  transition: all 0.2s;
}
.my-account .tabs .tab .text {
  font-size: 14px;
  transform: scale(1);
  transition: all 0.2s;
}
.my-account .tabs .tab.show {
  box-shadow: inset -3px 2px 5px rgba(0, 0, 0, 0.25);
  color: #3f4c7f;
}
.my-account .tabs .tab.show.signup {
  box-shadow: inset 3px 2px 5px rgba(0, 0, 0, 0.25);
}
.my-account .tabs .tab.show .text {
  transform: scale(0.9);
}
.my-account .pages.signup-page {
  transform: translateX(-100%);
}
.my-account .pages.login-page {
  transform: translateX(0%);
}
.my-account .pages .page.show .input {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.5s;
}
.my-account .pages .page.show .input:nth-child(1) {
  transition-delay: 0.2s;
}
.my-account .pages .page.show .input:nth-child(2) {
  transition-delay: 0.4s;
}
.my-account .pages .page.show .input:nth-child(3) {
  transition-delay: 0.6s;
}
@keyframes hi {
  from {
    transform: translateY(50%) scale(0, 0);
    opacity: 0;
  }
}

